import React from 'react';
import { InvertedPnLCellSpan, PnLCellSpan, TotalPnLCellSpan, OptionSellCellSpan, OptionBuyCellSpan, TotalRow } from './PnLTable.styles';

const formatNumber = (num) => {
    return Number(num).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const PnLCell = ({ value }) => (
    <PnLCellSpan value={value}>
        ₹{formatNumber(value)}
    </PnLCellSpan>
);

export const InvertedPnLCell = ({ value }) => (
    <InvertedPnLCellSpan value={value}>
        ₹{formatNumber(value)}
    </InvertedPnLCellSpan>
);

export const OptionSellCell = ({ value }) => (
    <OptionSellCellSpan>
        ₹{formatNumber(value)}
    </OptionSellCellSpan>
);

export const OptionBuyCell = ({ value }) => (
    <OptionBuyCellSpan>
        ₹{formatNumber(value)}
    </OptionBuyCellSpan>
);

export const NormalCurrencyCell = ({ value }) => (
    <div>
        ₹{formatNumber(value)}
    </div>
);

export const TotalPnLCell = ({ value }) => (
    <TotalRow>
        <TotalPnLCellSpan value={value}>
            ₹{formatNumber(value)}
        </TotalPnLCellSpan>
    </TotalRow>
);
