import React, { useMemo } from 'react';
import Table from '../../Table/Table';

const Sgb = ({ data }) => {
    const columns = useMemo(() => [
        { Header: 'Bond Symbol', accessor: 'bond_symbol' },
        { Header: 'Ask 1', accessor: 'ask1' },
        { Header: 'Ask 1 Quantity', accessor: 'ask1_quantity' },
        { Header: 'Bid 1', accessor: 'bid1' },
        { Header: 'Bid 1 Quantity', accessor: 'bid1_quantity' },
        { Header: 'Trade Volume', accessor: 'trade_volume' },
        { Header: 'Last Traded Price (LTP)', accessor: 'ltp' },
    ], []);

    return (
        <div className="table-container">
            <h1>Sovereign Gold Bond Data</h1>
            {data && data.length > 0 ? (
                <Table data={data} columns={columns} />
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default Sgb;
