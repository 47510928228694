import React, { useMemo } from 'react';
import Table from '../../Table/Table';

const NonSgb = ({ data }) => {
    const columns = useMemo(() => [
        {
            Header: 'ISIN',
            accessor: 'isin',
            Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
        },
        {
            Header: 'Symbol',
            accessor: 'symbol',
            Cell: ({ value, row }) => (
                <div style={{ textAlign: "center" }}>
                    {row.original.bse_scrip || value}
                </div>
            )
        },
        {
            Header: 'Coupon',
            accessor: 'coupon',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {`${(value * 100).toFixed(2)}%`}
                </div>
            )
        },
        {
            Header: 'Yield',
            accessor: 'ytm',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {`${value.toFixed(2)}%`}
                </div>
            )
        },
        {
            Header: 'Face value',
            accessor: 'face_value',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value.toFixed(2)}
                </div>
            )
        },
        {
            Header: 'Dirty price',
            accessor: 'dirty_price',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value.toFixed(2)}
                </div>
            )
        },
        {
            Header: 'Clean price',
            accessor: 'clean_price',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value.toFixed(2)}
                </div>
            )
        },
        {
            Header: 'Payment frequency',
            accessor: 'payment_frequency',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
        {
            Header: 'Time to maturity',
            accessor: 'days_to_maturity',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
        {
            Header: 'Issue date',
            accessor: 'issue_date',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
        {
            Header: 'Maturity date',
            accessor: 'maturity_date',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
        {
            Header: 'Last interest payment date',
            accessor: 'last_interest_payment_date',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
        {
            Header: 'Next interest payment date',
            accessor: 'next_interest_payment_date',
            Cell: ({ value }) => (
                <div style={{ textAlign: "center" }}>
                    {value}
                </div>
            )
        },
    ], []);

    return (
        <div className="table-container">
            {data && data.length > 0 ? (
                <Table data={data} columns={columns} />
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default NonSgb;
