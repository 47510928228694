import React, { createContext, useState, useContext } from 'react';

const PageContentContext = createContext();

export const PageContentProvider = ({ children }) => {
    const [persistentData, setPersistentData] = useState({
        pnlTable: {},
        bondsApp: {},
        optionWrites: {},
        strangles: {},
        creditSpreads: {},
        tradeInstances: {},
    });

    return (
        <PageContentContext.Provider value={{ persistentData, setPersistentData }}>
            {children}
        </PageContentContext.Provider>
    );
};

export const usePageContentContext = () => useContext(PageContentContext);
