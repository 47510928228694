import axios from 'axios';
import { generateOrderCommentUrl, discretionaryOrderUrl } from '../Constants';
import { handleApiError } from './helpers';

export const fetchOrders = async (pageNum = 1, limit = 1000) => {
    try {
        const response = await axios.get(`${discretionaryOrderUrl}?page=${pageNum}&limit=${limit}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

export const updateOrder = async (orderId, updateData) => {
    try {
        const response = await axios.put(`${discretionaryOrderUrl}${orderId}`, updateData);
        if (response.status !== 200) {
            return handleApiError(response);
        }
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

export const deleteOrder = async (orderId) => {
    try {
        const response = await axios.delete(`${discretionaryOrderUrl}${orderId}`);
        if (response.status !== 200) {
            return handleApiError(response);
        }
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};


export const updateComments = async (orderId, comments) => {
    try {
        const commentsArray = Array.isArray(comments) ? comments : [comments];
        const response = await axios.put(generateOrderCommentUrl(orderId), { comments: commentsArray });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

export const addComment = async (orderId, comment) => {
    try {
        const response = await axios.post(generateOrderCommentUrl(orderId), { comment });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

export const deleteComments = async (orderId) => {
    try {
        const response = await axios.delete(`${generateOrderCommentUrl(orderId)}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};