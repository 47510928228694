import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import StyledDatePicker from '../../Buttons/StyledDatePicker';
import { format } from 'date-fns';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { useDataContext } from '../../../contexts/DataContext';

const FormContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-end;
    background-color: var(--form-bg-color);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
`;

const InputContainer = styled.div`
    flex: 1;
    min-width: 120px;
    max-width: 200px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color);
`;

const inputStyles = css`
    width: 100%;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--form-bg-color);
    color: var(--text-color);
    &:focus {
        outline: none;
        border-color: var(--primary-color);
        box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
    }
`;

const Input = styled.input`
    ${inputStyles}
`;

const Dropdown = styled.select`
    ${inputStyles}
`;

const StyledDatePickerWrapper = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker-popper {
        z-index: 9999 !important;
    }
    .react-datepicker {
        background-color: var(--form-bg-color);
        color: var(--text-color);
        border-color: var(--border-color);
    }
    .react-datepicker__header {
        background-color: var(--bg-color);
        border-bottom-color: var(--border-color);
    }
    .react-datepicker__current-month,
    .react-datepicker__day-name,
    .react-datepicker__day {
        color: var(--text-color);
    }
    .react-datepicker__day:hover {
        background-color: var(--hover-bg-color);
    }
    .react-datepicker__day--selected {
        background-color: var(--primary-color);
        color: var(--button-text-color);
    }
`;

const ErrorMessage = styled.span`
    color: var(--delete-button-color);
    font-size: 12px;
    margin-top: 4px;
    display: block;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const Button = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.2s;
`;

const SearchButton = styled(Button)`
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    &:hover {
        background-color: ${props => props.theme.palette.primary.dark};
    }
`;

const ClearButton = styled(Button)`
    background-color: ${props => props.theme.palette.error.main};
    color: ${props => props.theme.palette.error.contrastText};
    &:hover {
        background-color: ${props => props.theme.palette.error.dark};
    }
`;

const OptionsFilterInputs = ({
    symbol,
    setSymbol,
    expectedPop,
    setExpectedPop,
    expectedRoi,
    setExpectedRoi,
    expiryReq,
    setExpiryReq,
    optionType,
    setOptionType,
    marginOfSafety,
    setMarginOfSafety,
    errors,
    handleKeyDown,
    fetchData,
    clearData,
}) => {
    const { theme } = useContext(ThemeContext);
    const { data } = useDataContext();

    const symbols = useMemo(() => {
        if (data.lotSizes) {
            return Object.keys(data.lotSizes);
        }
        return [];
    }, [data.lotSizes]);

    const renderSymbolDropdown = () => (
        <InputContainer>
            <Label>Symbol</Label>
            <Dropdown
                value={symbol}
                onChange={e => setSymbol(e.target.value)}
            >
                <option value="" disabled>Select a symbol</option>
                {symbols.map((sym) => (
                    <option key={sym} value={sym}>
                        {sym}
                    </option>
                ))}
            </Dropdown>
            {errors.symbol && <ErrorMessage>{errors.symbol}</ErrorMessage>}
        </InputContainer>
    );

    const renderInput = (label, value, setter, name) => (
        <InputContainer key={name}>
            <Label>{label}</Label>
            <Input
                type="text"
                value={value}
                placeholder={label}
                onChange={e => {
                    setter(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                className={errors[name] ? 'error' : ''}
            />
            {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
        </InputContainer>
    );

    return (
        <FormContainer>
            {renderSymbolDropdown()} {/* Use the dropdown for symbols */}
            {renderInput("Expected POP", expectedPop, setExpectedPop, "expectedPop")}
            {renderInput("Expected ROI", expectedRoi, setExpectedRoi, "expectedRoi")}
            <InputContainer>
                <Label>Expiry Req</Label>
                <StyledDatePickerWrapper>
                    <StyledDatePicker
                        selected={expiryReq ? new Date(expiryReq) : null}
                        onChange={(date) => setExpiryReq(date ? format(date, 'dd-MMM-yyyy') : null)}
                        dateFormat="dd-MMM-yyyy"
                        placeholderText="Expiry"
                    />
                </StyledDatePickerWrapper>
            </InputContainer>
            <InputContainer>
                <Label>Option Type</Label>
                <Dropdown
                    value={optionType}
                    onChange={e => setOptionType(e.target.value)}
                >
                    <option value="">Select Option Type</option>
                    <option value="CE">CE</option>
                    <option value="PE">PE</option>
                </Dropdown>
            </InputContainer>
            {renderInput("Margin of Safety", marginOfSafety, setMarginOfSafety, "marginOfSafety")}
            <ButtonContainer>
                <SearchButton onClick={fetchData} theme={theme}>Search</SearchButton>
                <ClearButton onClick={clearData} theme={theme}>Clear Data</ClearButton>
            </ButtonContainer>
        </FormContainer>
    );
};

export default OptionsFilterInputs;
