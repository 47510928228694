import React, { useState, useMemo, useCallback } from 'react';
import Table from '../../Table/Table';
import OptionsFilterInputs from './OptionsFilterInputs';
import { useStranglesContext } from '../../../contexts/StranglesContext';
import { format } from 'date-fns'; // Import date-fns for date formatting

const Strangles = ({ setIsDataLoading }) => {
    const { 
        stranglesData, 
        stranglesLoading, 
        fetchStrangles, 
        persistentData, 
        setPersistentData,
        setStranglesData
    } = useStranglesContext();

    const [errors, setErrors] = useState({
        symbol: '',
        expectedPop: '',
        expectedRoi: '',
        expiryReq: '',
        marginOfSafety: '',
    });

    const validateInputs = useCallback(() => {
        const newErrors = {
            expectedPop: !persistentData.expectedPop || isNaN(parseFloat(persistentData.expectedPop)) ? "Expected PoP must be a valid number" : "",
            expectedRoi: !persistentData.expectedRoi || isNaN(parseFloat(persistentData.expectedRoi)) ? "Expected ROI must be a valid number" : "",
            marginOfSafety: !persistentData.marginOfSafety || isNaN(parseFloat(persistentData.marginOfSafety)) ? "Margin of Safety must be a valid number" : "",
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === "");
    }, [persistentData]);

    const fetchData = useCallback(async () => {
        if (!validateInputs()) {
            return;
        }
        setIsDataLoading(true);
        await fetchStrangles();
        setIsDataLoading(false);
    }, [validateInputs, fetchStrangles, setIsDataLoading]);

    const clearData = useCallback(() => {
        setStranglesData([]);
    }, [setStranglesData]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchData();
        }
    };

    const columns = useMemo(() => [
        { Header: 'Symbol', accessor: 'symbol' },
        { 
            Header: 'Expiry', 
            accessor: (row) => {
                const callExpiry = format(new Date(row.call?.expiry), 'dd-MMM-yyyy');
                const putExpiry = format(new Date(row.put?.expiry), 'dd-MMM-yyyy');
                return callExpiry === putExpiry ? callExpiry : `${callExpiry} / ${putExpiry}`;
            },
            Cell: ({ value }) => value || '-'
        },
        { Header: 'MoS', accessor: 'mos' },
        { Header: 'PoP', accessor: 'pop' },
        { Header: 'ROI', accessor: 'roi' },
        { Header: 'Reward (₹)', accessor: 'premium' },
        { Header: 'Margin', accessor: 'capital' },
        { Header: 'TTE', accessor: 'time_to_expiry' },
        { Header: 'Spot', accessor: 'call.spot_price' },
        { Header: 'Call Strike', accessor: 'call.strike_price' },
        { Header: 'Call Best Bid', accessor: 'call.best_bid_price' },
        { Header: 'Call Best Ask', accessor: 'call.best_ask_price' },
        { Header: 'Call OI', accessor: 'call.open_interest' },
        { Header: 'Call OI Change', accessor: 'call.open_interest_change' },
        { Header: 'Call Delta', accessor: 'call.delta' },
        { Header: 'Call Theta', accessor: 'call.theta' },
        { Header: 'Call Gamma', accessor: 'call.gamma' },
        { Header: 'Call Vega', accessor: 'call.vega' },
        { Header: 'Put Strike', accessor: 'put.strike_price' },
        { Header: 'Put Best Bid', accessor: 'put.best_bid_price' },
        { Header: 'Put Best Ask', accessor: 'put.best_ask_price' },
        { Header: 'Put OI', accessor: 'put.open_interest' },
        { Header: 'Put OI Change', accessor: 'put.open_interest_change' },
        { Header: 'Put Delta', accessor: 'put.delta' },
        { Header: 'Put Theta', accessor: 'put.theta' },
        { Header: 'Put Gamma', accessor: 'put.gamma' },
        { Header: 'Put Vega', accessor: 'put.vega' },
    ], []);

    return (
        <>
            <OptionsFilterInputs
                {...persistentData}
                setSymbol={(value) => setPersistentData(prev => ({ ...prev, symbol: value }))}
                setExpectedPop={(value) => setPersistentData(prev => ({ ...prev, expectedPop: value }))}
                setExpectedRoi={(value) => setPersistentData(prev => ({ ...prev, expectedRoi: value }))}
                setExpiryReq={(value) => setPersistentData(prev => ({ ...prev, expiryReq: value }))}
                setMarginOfSafety={(value) => setPersistentData(prev => ({ ...prev, marginOfSafety: value }))}
                errors={errors}
                handleKeyDown={handleKeyDown}
                fetchData={fetchData}
                clearData={clearData}
            />
            <div>
                {stranglesLoading && <p>Loading...</p>}
                <Table data={stranglesData} columns={columns} />
            </div>
        </>
    );
};

export default Strangles;