import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { fetchCreditSpreadsData } from '../api/optionsAPI';

const CreditSpreadsContext = createContext();

export const CreditSpreadsProvider = ({ children }) => {
    const [creditSpreadsData, setCreditSpreadsData] = useState(() => {
        const savedData = localStorage.getItem('creditSpreadsData');
        return savedData ? JSON.parse(savedData) : [];
    });
    const [creditSpreadsLoading, setCreditSpreadsLoading] = useState(false);
    const [persistentData, setPersistentData] = useState(() => {
        const savedPersistentData = localStorage.getItem('creditSpreadsPersistentData');
        return savedPersistentData ? JSON.parse(savedPersistentData) : {
            symbol: '',
            expectedPop: '85',
            expectedRoi: '10',
            expiryReq: '',
            optionType: '',
            marginOfSafety: '5',
        };
    });

    useEffect(() => {
        localStorage.setItem('creditSpreadsData', JSON.stringify(creditSpreadsData));
    }, [creditSpreadsData]);

    useEffect(() => {
        localStorage.setItem('creditSpreadsPersistentData', JSON.stringify(persistentData));
    }, [persistentData]);

    const fetchCreditSpreads = useCallback(async () => {
        setCreditSpreadsLoading(true);
        try {
            const data = await fetchCreditSpreadsData(
                persistentData.symbol,
                persistentData.expectedPop,
                persistentData.expectedRoi,
                persistentData.expiryReq,
                persistentData.optionType,
                persistentData.marginOfSafety
            );
            setCreditSpreadsData(data);
        } catch (err) {
            console.error(`Error fetching credit spreads data: ${err}`);
        } finally {
            setCreditSpreadsLoading(false);
        }
    }, [persistentData]);

    return (
        <CreditSpreadsContext.Provider value={{ 
            creditSpreadsData, 
            setCreditSpreadsData, 
            creditSpreadsLoading, 
            fetchCreditSpreads, 
            persistentData, 
            setPersistentData 
        }}>
            {children}
        </CreditSpreadsContext.Provider>
    );
};

export const useCreditSpreadsContext = () => {
    return useContext(CreditSpreadsContext);
};