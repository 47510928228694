import { useState, useEffect } from 'react';
import { supabase } from '../components/Auth/supabaseClient';

const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loginProvider, setLoginProvider] = useState(null);

    useEffect(() => {
        const checkLoginStatus = async () => {
            await updateUserInfo();
            setIsLoading(false);
        };
        checkLoginStatus();
    }, []);

    const login = async (email, password) => {
        try {
            const { data, error } = await supabase.auth.signInWithPassword({ email, password });
            if (error) throw error;
            await updateUserInfo();
            localStorage.setItem('accessToken', data.session.access_token);
            return { success: true };
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    const signup = async (email, password) => {
        try {
            const { data, error } = await supabase.auth.signUp({ email, password });
            if (error) throw error;
            await updateUserInfo();
            if (data.session) {
                localStorage.setItem('accessToken', data.session.access_token);
            }
            return { success: true };
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    const handleSocialLogin = async (provider) => {
        try {
            let { data, error } = await supabase.auth.signInWithOAuth({
                provider: provider,
                options: {
                    redirectTo: window.location.origin,
                },
            });
            if (error) throw error;
            
            // After successful OAuth login, fetch the latest user data
            await updateUserInfo();
            
            setLoginProvider(provider);
            if (data.session) {
                localStorage.setItem('accessToken', data.session.access_token);
            }
        } catch (error) {
            console.error(`Unable to set ${loginProvider} Error with ${provider} authentication:`, error.message);
        }
    };

    const logout = async () => {
        try {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;

            setIsLoggedIn(false);
            setUserInfo(null);
            setLoginProvider(null);

            // Clear all user-related data from local storage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('userInfo');
            sessionStorage.removeItem('supabase.auth.token');

            // Clear any other Supabase-related items
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key && key.startsWith('sb-')) {
                    localStorage.removeItem(key);
                }
            }

        } catch (error) {
            console.error('Error during logout:', error.message);
        }
    };

    const resetPassword = async (email) => {
        try {
            const { error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: `${window.location.origin}/password-recovery`,
            });
            if (error) throw error;
            return { success: true };
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    const updateUserInfo = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            setUserInfo(user);
            setIsLoggedIn(true);
            localStorage.setItem('userInfo', JSON.stringify(user));
        }
    };

    return { isLoggedIn, userInfo, isLoading, login, signup, logout, resetPassword, handleSocialLogin };
};

export default useAuth;
