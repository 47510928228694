import React, { useState, useContext } from 'react';
import { LoginForm } from './LoginForm';
import { SignupForm } from './SignupForm';
import { SocialLogin } from './SocialLogin';
import { ThemeToggle } from '../../common/ThemeToggle';
import { ThemeContext } from '../../contexts/ThemeContext';
import useAuth from '../../hooks/useAuth';
import styles from './LoginPage.module.css';

export const LoginPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { login, signup, resetPassword, handleSocialLogin } = useAuth();

  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.formContainer}>
        <div className={styles.formHeader}>
          <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>
          <ThemeToggle />
        </div>
        {isLogin ? (
          <LoginForm onSubmit={login} onResetPassword={resetPassword} />
        ) : (
          <SignupForm onSubmit={signup} />
        )}
        <SocialLogin handleSocialLogin={handleSocialLogin} />
        <div className={styles.switchText}>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <button
            className={styles.toggleButton}
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Sign Up' : 'Login'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;