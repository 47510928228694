import axios from 'axios';
import { nseGsecUrl, nseCorpBondsUrl, nseSgbUrl, bseGsecUrl, bseCorpBondsUrl, bseSgbUrl } from '../Constants.js';

const urlOptions = {
    'NSE G-Sec': nseGsecUrl,
    'NSE Corporate Bonds': nseCorpBondsUrl,
    'NSE SGB': nseSgbUrl,
    'BSE G-Sec': bseGsecUrl,
    'BSE Corporate Bonds': bseCorpBondsUrl,
    'BSE SGB': bseSgbUrl,
};

const fetchBondsData = async (url, accessToken) => {
    try {
        const headers = { 'access_token': accessToken };
        const response = await axios.get(url, { headers });
        return response.data;
    } catch (error) {
        console.error("Error fetching bonds data:", error);
        throw error;
    }
};

export { urlOptions, fetchBondsData };
