import React, { useContext, useCallback, useEffect, Suspense, lazy } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import PnLTable from '../pages/trader/PnLTable';
import BondsApp from '../pages/bonds/Bonds';
import OptionWrites from '../pages/options/OptionWrites';
import Strangles from '../pages/options/Strangles';
import CreditSpreads from '../pages/options/CreditSpreads';
// import TradeInstances from '../pages/trader/TradeInstances';
// import TradeInstances from '../pages/positions/Positions';
// import Orders from '../pages/orders/Orders';
import { usePageContentContext } from './PageContentContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useTradeContext } from '../../contexts/TradeContext';
import { useOrderContext } from '../../contexts/OrderContext';
import styles from './PageContent.module.css';
import { useTransition } from 'react';
import { OptionWritesProvider } from '../../contexts/OptionWritesContext';
import { StranglesProvider } from '../../contexts/StranglesContext';
import { CreditSpreadsProvider } from '../../contexts/CreditSpreadsContext';

const TradeInstances = lazy(() => import('../pages/positions/Positions'));
const Orders = lazy(() => import('../pages/orders/Orders'));

const PageContent = ({ isDataLoading, setIsDataLoading, themeToggleButtonRef }) => {
    const location = useLocation();
    const { persistentData, setPersistentData } = usePageContentContext();
    const { theme } = useContext(ThemeContext);
    const [isPending, startTransition] = useTransition();
    const { fetchAllOpenPositions } = useTradeContext();
    const { fetchAllOrders } = useOrderContext();

    useEffect(() => {
        startTransition(() => {
            fetchAllOpenPositions();
            fetchAllOrders();
        });
    }, [fetchAllOpenPositions, fetchAllOrders]);

    const handleRouteChange = useCallback((Component) => {
        return (props) => (
            <Suspense fallback={<CircularProgress />}>
                <Component {...props} />
            </Suspense>
        );
    }, []);

    return (
        <div className={styles.container} data-theme={theme}>
            <Box p={3} position="relative">
                {(isDataLoading || isPending) && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bgcolor="rgba(255, 255, 255, 0.8)"
                        zIndex={1}
                    >
                        <CircularProgress size={60} thickness={4} />
                    </Box>
                )}
                <Routes location={location}>
                    <Route path="/" element={<Navigate to="/pnl" replace />} />
                    <Route path="/pnl" element={<PnLTable theme={theme} setIsDataLoading={setIsDataLoading} persistentData={persistentData.pnlTable} setPersistentData={(data) => setPersistentData(prev => ({ ...prev, pnlTable: data }))} />} />
                    <Route path="/bonds" element={<BondsApp theme={theme} setIsDataLoading={setIsDataLoading} persistentData={persistentData.bondsApp} setPersistentData={(data) => setPersistentData(prev => ({ ...prev, bondsApp: data }))} />} />
                    <Route path="/option-writes" element={<OptionWritesProvider><OptionWrites theme={theme} setIsDataLoading={setIsDataLoading} persistentData={persistentData.optionWrites} setPersistentData={(data) => setPersistentData(prev => ({ ...prev, optionWrites: data }))} /></OptionWritesProvider>} />
                    <Route path="/strangles" element={<StranglesProvider><Strangles theme={theme} setIsDataLoading={setIsDataLoading} persistentData={persistentData.strangles} setPersistentData={(data) => setPersistentData(prev => ({ ...prev, strangles: data }))} /></StranglesProvider>} />
                    <Route path="/credit-spreads" element={<CreditSpreadsProvider><CreditSpreads theme={theme} setIsDataLoading={setIsDataLoading} persistentData={persistentData.creditSpreads} setPersistentData={(data) => setPersistentData(prev => ({ ...prev, creditSpreads: data }))} /></CreditSpreadsProvider>} />
                    <Route path="/trade-instances" element={handleRouteChange(TradeInstances)({ theme, setIsDataLoading, persistentData: persistentData.tradeInstances, setPersistentData: (data) => setPersistentData(prev => ({ ...prev, tradeInstances: data })), themeToggleButtonRef })} />
                    <Route path="/orders" element={handleRouteChange(Orders)({ theme, setIsDataLoading, persistentData: persistentData.orders, setPersistentData: (data) => setPersistentData(prev => ({ ...prev, orders: data })), themeToggleButtonRef })} />
                </Routes>
            </Box>
        </div>
    );
};

export default PageContent;
