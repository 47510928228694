import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { fetchOptionWritesData } from '../api/optionsAPI';

const OptionWritesContext = createContext();

export const OptionWritesProvider = ({ children }) => {
    const [optionWritesData, setOptionWritesData] = useState(() => {
        const savedData = localStorage.getItem('optionWritesData');
        return savedData ? JSON.parse(savedData) : [];
    });
    const [optionWritesLoading, setOptionWritesLoading] = useState(false);
    const [persistentData, setPersistentData] = useState(() => {
        const savedPersistentData = localStorage.getItem('optionWritesPersistentData');
        return savedPersistentData ? JSON.parse(savedPersistentData) : {
            symbol: '',
            expectedPop: '85',
            expectedRoi: '10',
            expiryReq: '',
            optionType: '',
            marginOfSafety: '5',
        };
    });

    useEffect(() => {
        localStorage.setItem('optionWritesData', JSON.stringify(optionWritesData));
    }, [optionWritesData]);

    useEffect(() => {
        localStorage.setItem('optionWritesPersistentData', JSON.stringify(persistentData));
    }, [persistentData]);

    const fetchOptionWrites = useCallback(async () => {
        setOptionWritesLoading(true);
        try {
            const data = await fetchOptionWritesData(
                persistentData.symbol,
                persistentData.expectedPop,
                persistentData.expectedRoi,
                persistentData.expiryReq,
                persistentData.optionType,
                persistentData.marginOfSafety
            );
            setOptionWritesData(data);
        } catch (err) {
            console.error(`Error fetching option writes data: ${err}`);
        } finally {
            setOptionWritesLoading(false);
        }
    }, [persistentData]);

    return (
        <OptionWritesContext.Provider value={{ 
            optionWritesData, 
            setOptionWritesData, 
            optionWritesLoading, 
            fetchOptionWrites, 
            persistentData, 
            setPersistentData 
        }}>
            {children}
        </OptionWritesContext.Provider>
    );
};

export const useOptionWritesContext = () => {
    return useContext(OptionWritesContext);
};