import React, { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ThemeContext } from '../../contexts/ThemeContext';

const DraggableHeader = ({ column, index, moveColumn }) => {
    const { theme } = useContext(ThemeContext);
    const [, drag] = useDrag({
        type: 'COLUMN',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'COLUMN',
        hover(item) {
            if (item.index !== index) {
                moveColumn(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <th ref={(node) => drag(drop(node))} data-theme={theme}>
            <div className="header-content">
                <span className="header-text" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span className="sort-icon">
                        {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : ''}
                    </span>
                </span>
            </div>
            <div onClick={(e) => e.stopPropagation()}>
                {column.canFilter ? column.render('Filter') : null}
            </div>
        </th>
    );
};

export default DraggableHeader;
