import React, { useState, useMemo, useCallback } from 'react';
import Table from '../../Table/Table';
import OptionsFilterInputs from './OptionsFilterInputs';
import { useOptionWritesContext } from '../../../contexts/OptionWritesContext';

const OptionWrites = ({ setIsDataLoading }) => {
    const {
        optionWritesData,
        optionWritesLoading,
        fetchOptionWrites,
        persistentData,
        setPersistentData,
        setOptionWritesData
    } = useOptionWritesContext();

    const [errors, setErrors] = useState({
        symbol: '',
        expectedPop: '',
        expectedRoi: '',
        expiryReq: '',
        optionType: '',
        marginOfSafety: '',
    });

    const validateInputs = useCallback(() => {
        const newErrors = {
            expectedPop: !persistentData.expectedPop || isNaN(parseFloat(persistentData.expectedPop)) ? "Expected PoP must be a valid number" : "",
            expectedRoi: !persistentData.expectedRoi || isNaN(parseFloat(persistentData.expectedRoi)) ? "Expected ROI must be a valid number" : "",
            marginOfSafety: !persistentData.marginOfSafety || isNaN(parseFloat(persistentData.marginOfSafety)) ? "Margin of Safety must be a valid number" : "",
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === "");
    }, [persistentData]);

    const fetchData = useCallback(async () => {
        if (!validateInputs()) {
            return; // Prevent API call if there are validation errors
        }
        setIsDataLoading(true);
        await fetchOptionWrites();
        setIsDataLoading(false);
    }, [validateInputs, fetchOptionWrites, setIsDataLoading]);

    const clearData = useCallback(() => {
        setOptionWritesData([]);
    }, [setOptionWritesData]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchData();
        }
    };

    const columns = useMemo(() => [
        { Header: 'Symbol', accessor: 'symbol' },
        { Header: 'Expiry', accessor: 'expiry' },
        { Header: 'Spot', accessor: 'spot_price' },
        { Header: 'Strike', accessor: 'strike_price' },
        { Header: 'Option Type', accessor: 'option_type' },
        { Header: 'Lot Size', accessor: 'lot_size' },
        { Header: 'MoS', accessor: 'margin_of_safety' },
        { Header: 'PoP', accessor: 'probability_of_profit' },
        { Header: 'Capital', accessor: 'capital_required' },
        { Header: 'Reward (₹)', accessor: 'premium' },
        { Header: 'Charges', accessor: 'charges' },
        { Header: 'Expected ROI', accessor: 'expected_roi' },
        { Header: 'Time to Expiry', accessor: 'time_to_expiry' },
        { Header: 'Best Bid', accessor: 'best_bid_price' },
        { Header: 'Best Ask', accessor: 'best_ask_price' },
        { Header: 'OI', accessor: 'open_interest' },
        { Header: 'OI Change', accessor: 'open_interest_change' },
        { Header: 'Delta', accessor: 'delta' },
        { Header: 'Theta', accessor: 'theta' },
        { Header: 'Gamma', accessor: 'gamma' },
        { Header: 'Vega', accessor: 'vega' },
    ], []);

    return (
        <>
            <OptionsFilterInputs
                {...persistentData}
                setSymbol={(value) => setPersistentData(prev => ({ ...prev, symbol: value }))}
                setExpectedPop={(value) => setPersistentData(prev => ({ ...prev, expectedPop: value }))}
                setExpectedRoi={(value) => setPersistentData(prev => ({ ...prev, expectedRoi: value }))}
                setExpiryReq={(value) => setPersistentData(prev => ({ ...prev, expiryReq: value }))}
                setOptionType={(value) => setPersistentData(prev => ({ ...prev, optionType: value }))}
                setMarginOfSafety={(value) => setPersistentData(prev => ({ ...prev, marginOfSafety: value }))}
                errors={errors}
                handleKeyDown={handleKeyDown}
                fetchData={fetchData}
                clearData={clearData}
            />
            <div>
                {optionWritesLoading && <p>Loading...</p>}
                <Table data={optionWritesData} columns={columns} />
            </div>
        </>
    );
};

export default OptionWrites;