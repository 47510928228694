import axios from 'axios';
import { globalPnLUrl } from '../Constants';

const fetchPnLData = async () => {
    try {
        const response = await axios.get(globalPnLUrl);
        return response.data;
    } catch (error) {
        console.error("Error fetching PnL data:", error);
        throw error;
    }
};

export { fetchPnLData };
