import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useExpanded } from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableHeader from './DraggableHeader';
import DefaultColumnFilter from './DefaultColumnFilter';
import { ThemeContext } from '../../contexts/ThemeContext';
import { IconButton, Collapse, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './Table.css';

const Table = ({ 
    data, 
    columns: initialColumns, 
    isMobile = false,
    expandableRowKey = 'strategy_name'
}) => {
    const { theme } = useContext(ThemeContext);
    const [columns, setColumns] = useState(initialColumns);
    const [filterState, setFilterState] = useState({});
    const [sortBy, setSortBy] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});

    const updateFilterState = useCallback((columnId, filterValue) => {
        setFilterState(prevState => ({
            ...prevState,
            [columnId]: filterValue,
        }));
    }, []);

    const defaultColumn = useMemo(
        () => ({
            Filter: props => <DefaultColumnFilter {...props} updateFilterState={updateFilterState} />,
        }),
        [updateFilterState]
    );

    const moveColumn = (fromIndex, toIndex) => {
        const newColumns = [...columns];
        const [movedColumn] = newColumns.splice(fromIndex, 1);
        newColumns.splice(toIndex, 0, movedColumn);
        setColumns(newColumns);
    };

    const initialState = useMemo(() => ({
        filters: Object.entries(filterState).map(([id, value]) => ({ id, value })),
        sortBy: sortBy,
    }), [filterState, sortBy]);
    
    const toggleRowExpansion = useCallback((rowId) => {
        setExpandedRows(prev => ({
            ...prev,
            [rowId]: !prev[rowId]
        }));
    }, []);

    useEffect(() => {
        let updatedColumns = initialColumns.map(column => ({
            ...column,
            Filter: props => column.Filter ?
                <column.Filter {...props} updateFilterState={updateFilterState} /> :
                <DefaultColumnFilter {...props} updateFilterState={updateFilterState} />
        }));

        if (isMobile) {
            updatedColumns = updatedColumns.map(column => ({
                ...column,
                show: column.accessor === expandableRowKey
            }));
            updatedColumns.find(column => column.accessor === expandableRowKey).Cell = ({ row }) => (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <span>{row.values[expandableRowKey]}</span>
                    <IconButton onClick={() => toggleRowExpansion(row.id)} size="small">
                        <MenuIcon />
                    </IconButton>
                </Box>
            );
        }

        setColumns(updatedColumns);
    }, [initialColumns, updateFilterState, isMobile, expandableRowKey, toggleRowExpansion]);

    const tableInstance = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState,
            autoResetFilters: false,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded
    );

    useEffect(() => {
        tableInstance.setAllFilters(
            Object.entries(filterState).map(([id, value]) => ({ id, value }))
        );
    }, [filterState, tableInstance]);

    useEffect(() => {
        setSortBy(tableInstance.state.sortBy);
    }, [tableInstance.state.sortBy]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="table-container" data-theme={theme}>
                <div className="table-scroll">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                        column.show !== false && (
                                            <DraggableHeader
                                                key={column.id}
                                                column={column}
                                                index={index}
                                                moveColumn={moveColumn}
                                            />
                                        )
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={row.id}>
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                cell.column.show !== false && (
                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                )
                                            ))}
                                        </tr>
                                        {isMobile && (
                                            <tr>
                                                <td colSpan={columns.length}>
                                                    <Collapse in={expandedRows[row.id]}>
                                                        <Box className="expanded-row-content">
                                                            {columns.map(column => (
                                                                column.accessor !== expandableRowKey && (
                                                                    <div key={column.accessor} className="expanded-cell">
                                                                        <span className="expanded-cell-header">{column.Header}:</span>
                                                                        <span className="expanded-cell-value">
                                                                            {column.Cell ? column.Cell({ row }) : row.values[column.accessor]}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </Box>
                                                    </Collapse>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </DndProvider>
    );
};

export default Table;
