import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, useMediaQuery, IconButton, Menu, MenuItem, Avatar, Tabs, Tab, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/logo.png';
import { ThemeContext } from '../../contexts/ThemeContext'; // Add this import

const AppBarComponent = ({ userInfo, logout, menuItems, value, handleChange, handleMenu, handleClose, anchorEl }) => {
    const { theme, toggleTheme, themeToggleButtonRef } = useContext(ThemeContext); 
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Use theme from context

    const getUserInfo = () => {
        if (!userInfo) return { name: '', picture: '' };

        const { app_metadata, user_metadata, identities } = userInfo;
        const provider = app_metadata?.provider;

        if (provider === 'google' || provider === 'twitter' || provider === 'github') {
            const identity = identities.find(id => id.provider === provider);
            return {
                name: user_metadata?.full_name || user_metadata?.name || '',
                picture: user_metadata?.avatar_url || identity?.identity_data?.avatar_url || ''
            };
        }

        return { name: '', picture: '' };
    };

    const { name, picture } = getUserInfo();

    return (
        <AppBar position="sticky" elevation={4} sx={{}}>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <img
                        src={logoImage}
                        alt="Winvestors Dashboard"
                        style={{ height: '40px', marginRight: '10px' }}
                    />
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {menuItems.map((item, index) => (
                                <MenuItem key={index} onClick={handleClose} component={Link} to={item.path}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : (
                    <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" textColor="inherit">
                        {menuItems.map((item, index) => (
                            <Tab key={index} label={item.label} component={Link} to={item.path} />
                        ))}
                    </Tabs>
                )}
                {userInfo && (
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                        <Avatar src={picture} alt={name} />
                        <Typography variant="subtitle1" sx={{ ml: 1, mr: 2 }}>
                            {name}
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={logout}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            <LogoutIcon />
                        </IconButton>
                        <IconButton // Integrated ThemeToggle into the AppBar
                            color="inherit"
                            ref={themeToggleButtonRef}
                            onClick={toggleTheme} // Use toggleTheme directly
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            {theme.palette.mode === 'light' ? '🌙' : '☀️'} {/* Toggle icon based on theme */}
                        </IconButton>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarComponent;
