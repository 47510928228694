// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { ThemeProvider } from './contexts/ThemeContext';
import { TradeProvider } from './contexts/TradeContext';
import { OrderProvider } from './contexts/OrderContext';
import { DataProvider } from './contexts/DataContext';
import registerServiceWorker from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <TradeProvider>
                <OrderProvider>
                    <DataProvider>
                        <App />
                    </DataProvider>
                </OrderProvider>
            </TradeProvider>
        </ThemeProvider>
    </React.StrictMode>
);

registerServiceWorker();