import React, { useContext } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';

const DefaultColumnFilter = ({
    column: { id, filterValue, preFilteredRows, setFilter },
    updateFilterState,
}) => {
    const { theme } = useContext(ThemeContext);
    const count = preFilteredRows.length;

    return (
        <input
            className="column-filter"
            value={filterValue || ''}
            onChange={e => {
                const value = e.target.value || undefined;
                setFilter(value);
                updateFilterState(id, value);
            }}
            placeholder={`Search ${count} records...`}
            data-theme={theme}
        />
    );
};

export default DefaultColumnFilter;
