import React from 'react';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import styles from './LoginPage.module.css';

export const SocialLogin = ({ handleSocialLogin }) => {
  return (
    <div className={styles.socialLogin}>
      <button onClick={() => handleSocialLogin('google')} aria-label="Login with Google">
        <FcGoogle />
      </button>
      <button onClick={() => handleSocialLogin('twitter')} aria-label="Login with Twitter">
        <FaTwitter className={styles.twitter} />
      </button>
      <button onClick={() => handleSocialLogin('github')} aria-label="Login with GitHub">
        <FaGithub className={styles.github} />
      </button>
    </div>
  );
};
