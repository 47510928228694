import React, { useState, useEffect } from 'react';
import styles from './LoginPage.module.css';

export const SignupForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email) ? '' : 'Please enter a valid email address';
  };

  const validatePassword = (password) => {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return strongPasswordRegex.test(password) ? '' : 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character';
  };

  const validateConfirmPassword = (confirmPassword, password) => {
    return confirmPassword === password ? '' : 'Passwords do not match';
  };

  const handleBlur = (field) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (touchedFields.email) {
      setEmailError(validateEmail(newEmail));
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(validatePassword(newPassword));
    if (touchedFields.confirmPassword) {
      setConfirmPasswordError(validateConfirmPassword(confirmPassword, newPassword));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setConfirmPasswordError(validateConfirmPassword(newConfirmPassword, password));
  };

  useEffect(() => {
    if (touchedFields.email) {
      setEmailError(validateEmail(email));
    }
    if (touchedFields.password) {
      setPasswordError(validatePassword(password));
    }
    if (touchedFields.confirmPassword) {
      setConfirmPasswordError(validateConfirmPassword(confirmPassword, password));
    }
  }, [email, password, confirmPassword, touchedFields]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const emailValidationError = validateEmail(email);
    const passwordValidationError = validatePassword(password);
    const confirmPasswordValidationError = validateConfirmPassword(confirmPassword, password);

    setEmailError(emailValidationError);
    setPasswordError(passwordValidationError);
    setConfirmPasswordError(confirmPasswordValidationError);

    if (emailValidationError || passwordValidationError || confirmPasswordValidationError) {
      return;
    }

    const result = await onSubmit(email, password);
    if (!result.success) {
      setError(result.error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.inputGroup}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          onBlur={() => handleBlur('email')}
          required
        />
        {touchedFields.email && emailError && <span className={styles.errorMessage}>{emailError}</span>}
      </div>
      <div className={styles.inputGroup}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          onBlur={() => handleBlur('password')}
          required
        />
        {touchedFields.password && passwordError && <span className={styles.errorMessage}>{passwordError}</span>}
      </div>
      <div className={styles.inputGroup}>
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          onBlur={() => handleBlur('confirmPassword')}
          required
        />
        {touchedFields.confirmPassword && confirmPasswordError && <span className={styles.errorMessage}>{confirmPasswordError}</span>}
      </div>
      <button type="submit" className={styles.primaryButton}>Sign Up</button>
    </form>
  );
};
