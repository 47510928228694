import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: Arial, sans-serif;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: var(--form-bg-color);
  }

  .react-datepicker__header {
    background-color: var(--bg-color);
    border-bottom: 1px solid var(--border-color);
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--text-color);
  }

  .react-datepicker__day:hover {
    background-color: var(--hover-bg-color);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--primary-color);
    color: var(--button-text-color);
  }

  .react-datepicker__day--disabled {
    color: var(--border-color);
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--form-bg-color);
    color: var(--text-color);
  }

  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }

  .react-datepicker__triangle {
    border-bottom-color: var(--bg-color) !important;
  }

  .react-datepicker__triangle::before {
    border-bottom-color: var(--border-color) !important;
  }

  .react-datepicker__navigation-icon::before {
    border-color: var(--text-color);
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow {
    border-color: var(--text-color);
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    background-color: var(--form-bg-color);
    border: 1px solid var(--border-color);
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover {
    background-color: var(--hover-bg-color);
  }

  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected {
    background-color: var(--primary-color);
    color: var(--button-text-color);
  }
`;

const StyledDatePicker = ({ selected, onChange, dateFormat = 'dd-MMM-yyyy', placeholderText = 'Select date', ...props }) => {
  

  return (
    <StyledDatePickerWrapper>
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        {...props}
      />
    </StyledDatePickerWrapper>
  );
};

export default StyledDatePicker;
