import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const Dropdown = ({ selectedValue, onChange, options }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            select
            label="Bond Type"
            value={selectedValue}
            onChange={handleChange}
            variant="outlined"
            size="small"
            sx={{ m: '0.75rem', minWidth: 200 }}
        >
            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default Dropdown;
