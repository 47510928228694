export const BASE_URL_OPTIONS = 'https://options.winvestors.duckdns.org';
export const BASE_URL = 'https://db.winvestors.duckdns.org';
export const BASE_URL_DISRECTIONARY = 'https://trader.winvestors.duckdns.org';
export const BASE_URL_MARKET_DATA = 'https://data.winvestors.duckdns.org';
// export const BASE_URL = 'https://localhost:8014';
// export const BASE_URL_DISRECTIONARY = 'https://localhost:5001';


export const discretionaryOrderUrl = `${BASE_URL_DISRECTIONARY}/orders/`;
export const disretionaryTradeInstanceUrl = `${BASE_URL_DISRECTIONARY}/trade-instance/`;
export const strategiesUrl = `${BASE_URL}/strategies/`;
export const creditSpreadsUrl = `${BASE_URL}/credit-spreads-for-symbol/`;
export const globalPnLUrl = `${BASE_URL}/pnl`;

// Market Data
export const ltpUrl = `${BASE_URL_MARKET_DATA}/ltp/`;

// Orders
export const ordersUrl = `${BASE_URL}/orders/`;
export const updateBatchOrderUrl = `${BASE_URL}/orders/batch/`;
export const generateOrderCommentUrl = (orderId) => {
    return `${discretionaryOrderUrl}${orderId}/comment`;
};

// Bonds
// export const BASE_URL_BONDS = 'http://localhost:8009';
export const BASE_URL_BONDS = 'https://bonds.winvestors.duckdns.org';
export const nseGsecUrl = `${BASE_URL_BONDS}/get-nse-gsec-data/`
export const nseCorpBondsUrl = `${BASE_URL_BONDS}/get-nse-corp-bond-data/`
export const nseSgbUrl = `${BASE_URL_BONDS}/get-nse-sgb-data/`
export const bseGsecUrl = `${BASE_URL_BONDS}/get-bse-gsec-data/`
export const bseCorpBondsUrl = `${BASE_URL_BONDS}/get-bse-corp-bond-data/`
export const bseSgbUrl = `${BASE_URL_BONDS}/get-bse-sgb-data/`

// Options
export const generateGetStranglesUrl = (symbol, expectedPop, expectedRoi, expiryReq, marginOfSafety) => {
    return `${BASE_URL_OPTIONS}/strangles/?symbol=${symbol}&expected_pop=${expectedPop}&expected_roi=${expectedRoi}&expiry_req=${expiryReq}&margin_of_safety=${marginOfSafety}`
};
export const generateGetOptionWritesUrl = (symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety) => {
    return symbol
        ? `${BASE_URL_OPTIONS}/get-option-writes-for-symbol/?symbol=${symbol}&margin_of_safety=${marginOfSafety}&expected_pop=${expectedPop}&expected_roi=${expectedRoi}&expiry_req=${expiryReq}&option_type=${optionType}`
        : `${BASE_URL_OPTIONS}/refresh-option-writes/?margin_of_safety=${marginOfSafety}&expected_pop=${expectedPop}&expected_roi=${expectedRoi}&expiry_req=${expiryReq}&option_type=${optionType}`;
};

export const generateGetCreditSpreadsUrl = (symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety) => {
    return `${BASE_URL_OPTIONS}/credit-spreads-for-symbol/?symbol=${symbol}&expected_pop=${expectedPop}&expected_roi=${expectedRoi}&expiry_req=${expiryReq}&margin_of_safety=${marginOfSafety}&option_type=${optionType}`
};


// Strategy
export const STRATEGY = "strategy";
export const STRATEGIES = "strategies";
export const strategyUrl = `${BASE_URL}/${STRATEGY}/`;
export const getStrategiesUrl = `${BASE_URL}/${STRATEGIES}/?limit=25`;
export const getStrategyByNameUrl = `${BASE_URL}/${STRATEGY}/?name=`;

// Trade Instances
export const TRADE_INSTANCE = "trade-instance";
export const TRADE_INSTANCES = "trade-instances";
export const TRADE_INSTANCES_BY_STRATEGY = "trade-instances-by-strategy";
export const TRADE_INSTANCES_BY_ACCOUNT = "trade-instances-by-account";
export const TRADE_INSTANCES_BY_BROKER = "trade-instances-by-broker";
export const tradeInstanceUrl = `${BASE_URL}/${TRADE_INSTANCE}/`;
export const getTradeInstancesUrl = `${BASE_URL}/${TRADE_INSTANCES}/?`;
export const openTradeInstancesUrl = `${BASE_URL}/${TRADE_INSTANCES}/open/?`;
export const closedTradeInstancesUrl = `${BASE_URL}/${TRADE_INSTANCES}/closed/?`;
export const getTradeInstancesByStrategyIdUrl = `${BASE_URL}/${TRADE_INSTANCES_BY_STRATEGY}/?page=0&limit=100`;
export const getTradeInstancesByAccountIdUrl = `${BASE_URL}/${TRADE_INSTANCES_BY_ACCOUNT}/?page=0&limit=100`;
export const getTradeInstancesByBrokerIdUrl = `${BASE_URL}/${TRADE_INSTANCES_BY_BROKER}/?page=0&limit=100`;
export const generateDeleteTradeInstanceUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/close/`;
};
export const generateUpdateTradeInstanceExitTypeUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/exit-type/`;
};
export const generateTradeInstanceCommentsUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/comments/`;
};
export const generateUpdateTradeInstanceIdUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/update-trade-instance-id/`;
};
export const generateTradeInstanceSwitchToManualUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/switch-to-manual-mode/`;
};
export const generateTradeInstanceSwitchToAutoUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/switch-to-auto-mode/`;
};
export const generateTradeInstanceExpiriesUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/expiries/`;
};
export const generateTradeInstanceCapitalUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/capital/`;
};
export const generateGetTradeInstanceNetPnLUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/net-pnl/`;
};
export const generateTradeInstanceNetPnLUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_INSTANCE}/${tradeInstanceId}/net-pnl/`;
};


// Trade Legs
export const TRADE_LEG = "trade-leg";
export const TRADE_LEGS = "trade-legs";
export const TRADE_LEGS_BY_ACCOUNT = "trade-legs-by-account";
export const tradeLegUrl = `${BASE_URL}/${TRADE_LEG}/`;
export const tradeLegsUrl = `${BASE_URL}/${TRADE_LEGS}/`;
export const getTradeLegsByAccountIdUrl = `${BASE_URL}/${TRADE_LEGS_BY_ACCOUNT}/?page=0&limit=100`;
export const generateExitTradeLegUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}}/${tradeLegId}/exit/`;
};
export const generateUpdateTradeLegSellPriceUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/sell-price/`;
};
export const generateUpdateTradeLegBuyPriceUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/buy-price/`;
};
export const generateUpdateTradeLegQuantityUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/quantity/`;
};
export const generateUpdateTradeLegNetUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/net/`;
};
export const generateUpdateTradeLegExitTypeUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/exit-type/`;
};
export const generateTradeLegCommentsUrl = (tradeLegId) => {
    return `${BASE_URL}/${TRADE_LEG}/${tradeLegId}/comments/`;
};
export const generateDiscretionaryTradeLegUrl = (accountId) => {
    return `${discretionaryOrderUrl}${accountId}`;
};
export const generateDiscretionaryOrderUrl = (accountId) => {
    return `${discretionaryOrderUrl}${accountId}/`;
};

// Configurations
export const TRADE_CONFIG = "trade-config";
export const MARKET_CONFIG = "market-config";
export const BROKER_CONFIG = "broker-config";
export const TRADE_ENTRY_CONFIG = "trade-entry-config";
export const TRADE_ADJUSTMENT_CONFIG = "trade-adjustment-config";
export const generateGetTradeConigurationsUrl = (tradeInstanceId) => {
    return `${BASE_URL}/${TRADE_CONFIG}/full/${TRADE_INSTANCE}/${tradeInstanceId}`;
};
export const marketConfigUrl = `${BASE_URL}/${MARKET_CONFIG}/`;
export const generateUpdateMarketConfigUrl = (marketConfigId) => {
    return `${BASE_URL}/${MARKET_CONFIG}/${marketConfigId}`;
}
export const brokerConfigUrl = `${BASE_URL}/${BROKER_CONFIG}`;
export const generateUpdateBrokerConfigUrl = (brokerConfigId) => {
    return `${BASE_URL}/${BROKER_CONFIG}/${brokerConfigId}`;
}
export const tradeEntryConfigUrl = `${BASE_URL}/${TRADE_ENTRY_CONFIG}`;
export const generateUpdateTradeEntryConfigUrl = (tradeEntryConfigId) => {
    return `${BASE_URL}/${TRADE_ENTRY_CONFIG}/${tradeEntryConfigId}`;
}
export const tradeAdjustmentConfigUrl = `${BASE_URL}/${TRADE_ADJUSTMENT_CONFIG}`;
export const generateUpdateTradeAdjustmentConfigUrl = (tradeAdjustmentConfigId) => {
    return `${BASE_URL}/${TRADE_ADJUSTMENT_CONFIG}/${tradeAdjustmentConfigId}`;
}

// PnL
export const PNL = "pnl";
export const getPnLUrl = `${BASE_URL}/${PNL}`;
export const getStrategyPnLUrl = `${BASE_URL}/${PNL}/strategy`;
export const getTradeInstancePnLUrl = `${BASE_URL}/${PNL}/trade-instance`;

export const exitTypeMapping = {
    '-1': 'UNKNOWN',
    '0': 'NORMAL_PROFIT_BOOKING',
    '1': 'STOPLOSS_HIT',
    '2': 'TRAILING_STOPLOSS_HIT',
    '3': 'UNFAVOURABLE_RISK_REWARD',
    '4': 'ADJUSTED_FOR_DELTA_NEUTRALITY',
    '5': 'POST_EXPIRY',
    '6': 'STRANGLE_TO_STRADDLE',
    '7': 'NEGATIVE_PENDING_ROI',
    '8': 'DELTA_REVERSAL',
    '9': 'SHIFT_BUY_HEDGE_POTENTIAL_REVERSAL',
    '10': 'SHIFT_BUY_HEDGE_PROTECTION',
    '11': 'INTRINSIC_VALUE_BOOKING',
    '12': 'LARGE_STRANGLE_PRICE_DIFFERENCE',
    '13': 'STRADDLE_TO_JADE_LIZARD',
    '14': 'JADE_LIZARD_TO_STRADDLE',
    '15': 'POTENTIAL_VOL_SPIKE'
};