import React, { useState, useCallback, useContext, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, CssBaseline, CircularProgress } from '@mui/material';
import AppBarComponent from './components/Layout/AppBar';
import LoginPage from './components/Auth/LoginPage';
import useAuth from './hooks/useAuth';
import PageContent from './components/Layout/PageContent';
import { PageContentProvider } from './components/Layout/PageContentContext';
import { PasswordRecoveryForm } from './components/Auth/PasswordRecoveryForm';
import { ThemeContext } from './contexts/ThemeContext';
import { ThemeToggle } from './common/ThemeToggle';

const menuItems = [
    { label: "PnL", path: "/pnl" },
    { label: "Bonds", path: "/bonds" },
    { label: "Option Writes", path: "/option-writes" },
    { label: "Strangles", path: "/strangles" },
    { label: "Credit Spreads", path: "/credit-spreads" },
    { label: "Trade Instances", path: "/trade-instances" },
    { label: "Orders", path: "/orders" },
];

function App() {
    const [value, setValue] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const { isLoggedIn, userInfo, isLoading, logout } = useAuth();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const { theme } = useContext(ThemeContext);
    const themeToggleButtonRef = useRef(null);

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
    }, []);

    const handleMenu = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }

    return (
        <PageContentProvider>
            <div data-theme={theme}>
                <Router>
                    <CssBaseline />
                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                        {isLoggedIn && (
                            <AppBarComponent
                                userInfo={userInfo}
                                logout={logout}
                                menuItems={menuItems}
                                value={value}
                                handleChange={handleChange}
                                handleMenu={handleMenu}
                                handleClose={handleClose}
                                anchorEl={anchorEl}
                                themeToggleButtonRef={themeToggleButtonRef}
                            />
                        )}
                        <ThemeToggle />
                        <Routes>
                            {isLoggedIn ? (
                                <Route path="*" element={<PageContent isDataLoading={isDataLoading} setIsDataLoading={setIsDataLoading} themeToggleButtonRef={themeToggleButtonRef} />} />
                            ) : (
                                <>
                                    <Route path="/password-reset" element={<PasswordRecoveryForm />} />
                                    <Route path="*" element={<LoginPage />} />
                                </>
                            )}
                        </Routes>
                    </Box>
                </Router>
            </div>
        </PageContentProvider>
    );
}

export default App;