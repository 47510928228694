import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import fetchFnOSymbols from '../api/helperDhanAPI';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('data');
        return savedData ? JSON.parse(savedData) : { lotSizes: [] };
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        localStorage.setItem('data', JSON.stringify(data));
    }, [data]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            let symbolsData = await fetchFnOSymbols();
            symbolsData = {
                ...symbolsData,
                "SENSEX": 10
            };
            setData((prevData) => ({ ...prevData, lotSizes: symbolsData })); // Use a callback to update data
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    }, []); // No dependency on 'data'

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <DataContext.Provider value={{ data, isLoading }}>
            {children}
        </DataContext.Provider>
    );
};

export const useDataContext = () => {
    return useContext(DataContext);
};
