import React, { useState, useEffect, useMemo, useRef } from 'react';
import Table from '../../Table/Table';
import MultiSelectFilter from '../../MultiSelectFilter';
import { fetchPnLData } from '../../../api/pnlAPI';
import {
    TableContainer,
    SummaryTablesContainer,
    SummaryTableWrapper,
} from './PnLTable.styles';
import { PnLCell } from './PnLCells';
import styled from 'styled-components';

const TableHeader = styled.h3`
  margin-bottom: 10px;
  display: flex;                // Use flexbox for layout
  justify-content: center;      // Center content horizontally
  align-items: center;          // Center content vertically
`;

const TotalPnL = styled.span`
  font-weight: inherit;
  color: inherit;
  margin-left: 10px;           // Add some space between the text and the PnL value
`;

const PnL = ({ theme }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;

            try {
                const responseData = await fetchPnLData();
                setData(responseData);
                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Strategy',
                accessor: 'strategy',
                width: 150, // Set a fixed width
            },
            {
                Header: 'PnL (₹)',
                accessor: 'pnl',
                width: 100, // Set a fixed width
                sortType: (rowA, rowB) => {
                    const a = parseFloat(rowA.values.pnl);
                    const b = parseFloat(rowB.values.pnl);
                    return a - b;
                },
                Cell: ({ value }) => <PnLCell value={value} />,
            },
        ],
        []
    );

    const detailedColumns = useMemo(
        () => [
            {
                Header: 'Strategy',
                accessor: 'strategy',
                Filter: MultiSelectFilter,
                filter: 'includesSome',
                width: 150, // Set a fixed width
            },
            {
                Header: 'Broker',
                accessor: 'broker',
                Filter: MultiSelectFilter,
                filter: 'includesSome',
                width: 100, // Set a fixed width
            },
            {
                Header: 'Account',
                accessor: 'account',
                Filter: MultiSelectFilter,
                filter: 'includesSome',
                width: 100, // Set a fixed width
            },
            {
                Header: 'Expiry',
                accessor: 'expiry',
                Filter: MultiSelectFilter,
                filter: 'includesSome',
                width: 100, // Set a fixed width
            },
            {
                Header: 'PnL (₹)',
                accessor: 'pnl',
                Cell: ({ value }) => <PnLCell value={value} />,
                width: 100, // Set a fixed width
                sortType: (rowA, rowB) => {
                    const a = parseFloat(rowA.values.pnl);
                    const b = parseFloat(rowB.values.pnl);
                    return a - b;
                },
            },
        ],
        []
    );

    const { liveSummaryData, paperSummaryData, detailedData, liveTotalPnL, paperTotalPnL } = useMemo(() => {
        if (!data) return { liveSummaryData: [], paperSummaryData: [], detailedData: [], liveTotalPnL: 0, paperTotalPnL: 0 };

        const liveSummary = [];
        const paperSummary = [];
        const detailed = [];
        let totalLivePnL = 0;
        let totalPaperPnL = 0;

        Object.entries(data).forEach(([strategy, details]) => {
            if (strategy !== 'Overall PnL') {
                let livePnL = 0;
                let paperPnL = 0;

                Object.entries(details).forEach(([broker, accounts]) => {
                    if (broker !== 'Strategy PnL') {
                        Object.entries(accounts).forEach(([account, accountDetails]) => {
                            const accountPnL = accountDetails['Account PnL'];

                            if (account !== 'Paper') {
                                livePnL += accountPnL;
                            } else {
                                paperPnL += accountPnL;
                            }

                            Object.entries(accountDetails).forEach(([expiry, pnl]) => {
                                if (expiry !== 'Account PnL') {
                                    detailed.push({
                                        strategy,
                                        broker,
                                        account,
                                        expiry,
                                        pnl,
                                    });
                                }
                            });
                        });
                    }
                });

                if (livePnL !== 0) {
                    liveSummary.push({ strategy, pnl: livePnL });
                    totalLivePnL += livePnL;
                }
                if (paperPnL !== 0) {
                    paperSummary.push({ strategy, pnl: paperPnL });
                    totalPaperPnL += paperPnL;
                }
            }
        });

        return {
            liveSummaryData: liveSummary,
            paperSummaryData: paperSummary,
            detailedData: detailed,
            liveTotalPnL: totalLivePnL,
            paperTotalPnL: totalPaperPnL
        };
    }, [data]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!data) return <div>No data available</div>;

    return (
        <TableContainer data-theme={theme}>
            <h2>PnL Summary</h2>
            <SummaryTablesContainer>
                <SummaryTableWrapper>
                    <TableHeader>
                        Live Trades <TotalPnL><PnLCell value={liveTotalPnL} /></TotalPnL>
                    </TableHeader>
                    <Table data={liveSummaryData} columns={columns}>
                        <thead>
                            <tr>
                                {columns.map(column => (
                                    <th key={column.accessor}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {liveSummaryData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map(column => (
                                        <td key={column.accessor}>{column.Cell ? column.Cell({ value: row[column.accessor] }) : row[column.accessor]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </SummaryTableWrapper>
                <SummaryTableWrapper>
                    <TableHeader>
                        Paper Trades <TotalPnL><PnLCell value={paperTotalPnL} /></TotalPnL>
                    </TableHeader>
                    <Table data={paperSummaryData} columns={columns}>
                        <thead>
                            <tr>
                                {columns.map(column => (
                                    <th key={column.accessor}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paperSummaryData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map(column => (
                                        <td key={column.accessor}>{column.Cell ? column.Cell({ value: row[column.accessor] }) : row[column.accessor]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </SummaryTableWrapper>
            </SummaryTablesContainer>

            <h2>Detailed Breakdown</h2>
            <Table data={detailedData} columns={detailedColumns}>
                <thead>
                    <tr>
                        {detailedColumns.map(column => (
                            <th key={column.accessor}>{column.Header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {detailedData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {detailedColumns.map(column => (
                                <td key={column.accessor}>{column.Cell ? column.Cell({ value: row[column.accessor] }) : row[column.accessor]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export default PnL;
