import axios from 'axios';
import { generateGetCreditSpreadsUrl, generateGetOptionWritesUrl, generateGetStranglesUrl } from '../Constants';

const fetchCreditSpreadsData = async (symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety) => {
    try {
        const url = generateGetCreditSpreadsUrl(symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching credit spreads data:", error);
        throw error;
    }
};

const fetchOptionWritesData = async (symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety) => {
    try {
        const url = generateGetOptionWritesUrl(symbol, expectedPop, expectedRoi, expiryReq, optionType, marginOfSafety);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching option writes data:", error);
        throw error;
    }
};

const fetchStranglesData = async (symbol, expectedPop, expectedRoi, expiryReq, marginOfSafety) => {
    try {
        const url = generateGetStranglesUrl(symbol, expectedPop, expectedRoi, expiryReq, marginOfSafety);
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching strangles data:", error);
        throw error;
    }
};

export { fetchCreditSpreadsData, fetchOptionWritesData, fetchStranglesData };
