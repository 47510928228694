import React, { useState, useMemo, useCallback } from 'react';
import Table from '../../Table/Table';
import OptionsFilterInputs from './OptionsFilterInputs';
import { useCreditSpreadsContext } from '../../../contexts/CreditSpreadsContext';
import { format } from 'date-fns';

const CreditSpreads = ({ setIsDataLoading }) => {
    const { 
        creditSpreadsData, 
        creditSpreadsLoading, 
        fetchCreditSpreads, 
        persistentData, 
        setPersistentData,
        setCreditSpreadsData
    } = useCreditSpreadsContext();

    const [errors, setErrors] = useState({
        symbol: '',
        expectedPop: '',
        expectedRoi: '',
        expiryReq: '',
        optionType: '',
        marginOfSafety: '',
    });

    const validateInputs = useCallback(() => {
        const newErrors = {
            expectedPop: !persistentData.expectedPop || isNaN(parseFloat(persistentData.expectedPop)) ? "Expected PoP must be a valid number" : "",
            expectedRoi: !persistentData.expectedRoi || isNaN(parseFloat(persistentData.expectedRoi)) ? "Expected ROI must be a valid number" : "",
            marginOfSafety: !persistentData.marginOfSafety || isNaN(parseFloat(persistentData.marginOfSafety)) ? "Margin of Safety must be a valid number" : "",
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === "");
    }, [persistentData]);

    const fetchData = useCallback(async () => {
        if (!validateInputs()) {
            return;
        }
        setIsDataLoading(true);
        await fetchCreditSpreads();
        setIsDataLoading(false);
    }, [validateInputs, fetchCreditSpreads, setIsDataLoading]);

    const clearData = useCallback(() => {
        setCreditSpreadsData([]);
    }, [setCreditSpreadsData]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchData();
        }
    };

    const columns = useMemo(() => [
        { Header: 'Symbol', accessor: 'symbol' },
        { 
            Header: 'Expiry', 
            accessor: (row) => {
                const expiryDate = row.sell?.expiry || row.buy?.expiry;
                return expiryDate ? format(new Date(expiryDate), 'dd-MMM-yyyy') : '-';
            }
        },
        { Header: 'MoS', accessor: 'mos' },
        { Header: 'PoP', accessor: 'pop' },
        { Header: 'ROI', accessor: 'roi' },
        { Header: 'Margin', accessor: 'capital' },
        { Header: 'TTE', accessor: 'time_to_expiry' },
        {
            Header: 'Risk',
            accessor: row => {
                const risk = ((row.sell.strike_price - row.buy.strike_price) * row.sell.lot_size);
                return risk;
            },
            sortType: 'basic'
        },
        { Header: 'Reward', accessor: 'premium' },
        { Header: 'Spot', accessor: 'sell.spot_price' },
        { Header: 'Side', accessor: 'sell.option_type' },
        { Header: 'Sell', accessor: 'sell.strike_price' },
        { Header: 'Sell Best Bid', accessor: 'sell.best_bid_price' },
        { Header: 'Sell Best Ask', accessor: 'sell.best_ask_price' },
        { Header: 'Sell OI', accessor: 'sell.open_interest' },
        { Header: 'Sell OI Change', accessor: 'sell.open_interest_change' },
        { Header: 'Sell Delta', accessor: 'sell.delta' },
        { Header: 'Sell Theta', accessor: 'sell.theta' },
        { Header: 'Sell Gamma', accessor: 'sell.gamma' },
        { Header: 'Sell Vega', accessor: 'sell.vega' },
        { Header: 'Buy', accessor: 'buy.strike_price' },
        { Header: 'Buy Best Bid', accessor: 'buy.best_bid_price' },
        { Header: 'Buy Best Ask', accessor: 'buy.best_ask_price' },
        { Header: 'Buy OI', accessor: 'buy.open_interest' },
        { Header: 'Buy OI Change', accessor: 'buy.open_interest_change' },
        { Header: 'Buy Delta', accessor: 'buy.delta' },
        { Header: 'Buy Theta', accessor: 'buy.theta' },
        { Header: 'Buy Gamma', accessor: 'buy.gamma' },
        { Header: 'Buy Vega', accessor: 'buy.vega' },
    ], []);

    return (
        <>
            <OptionsFilterInputs
                {...persistentData}
                setSymbol={(value) => setPersistentData(prev => ({ ...prev, symbol: value }))}
                setExpectedPop={(value) => setPersistentData(prev => ({ ...prev, expectedPop: value }))}
                setExpectedRoi={(value) => setPersistentData(prev => ({ ...prev, expectedRoi: value }))}
                setExpiryReq={(value) => setPersistentData(prev => ({ ...prev, expiryReq: value }))}
                setOptionType={(value) => setPersistentData(prev => ({ ...prev, optionType: value }))}
                setMarginOfSafety={(value) => setPersistentData(prev => ({ ...prev, marginOfSafety: value }))}
                errors={errors}
                handleKeyDown={handleKeyDown}
                fetchData={fetchData}
                clearData={clearData}
            />
            <div>
                {creditSpreadsLoading && <p>Loading...</p>}
                <Table data={creditSpreadsData} columns={columns} />
            </div>
        </>
    );
};

export default CreditSpreads;