import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchOrders } from '../api/ordersAPI';
import { format } from 'date-fns';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [allOrders, setAllOrders] = useState(() => {
        const savedOrders = localStorage.getItem('allOrders');
        return savedOrders ? JSON.parse(savedOrders) : [];
    });
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [persistentData, setPersistentData] = useState(() => {
        const savedPersistentData = localStorage.getItem('ordersPersistentData');
        return savedPersistentData ? JSON.parse(savedPersistentData) : {
            // Add any filter options here if needed
        };
    });

    useEffect(() => {
        localStorage.setItem('allOrders', JSON.stringify(allOrders));
    }, [allOrders]);

    useEffect(() => {
        localStorage.setItem('ordersPersistentData', JSON.stringify(persistentData));
    }, [persistentData]);

    const fetchAllOrders = useCallback(async () => {
        setOrdersLoading(true);
        try {
            let allFetchedOrders = [];
            let pageNum = 1;
            const limit = 100;
            let hasMoreData = true;

            while (hasMoreData) {
                const orders = await fetchOrders(pageNum, limit);
                if (orders.length > 0) {
                    allFetchedOrders = [...allFetchedOrders, ...orders];
                    pageNum++;
                    if (orders.length < limit) {
                        hasMoreData = false;
                    }
                } else {
                    hasMoreData = false;
                }
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            const formattedOrders = allFetchedOrders.map(order => ({
                ...order,
                created_at: format(new Date(order.created_at), 'yyyy-MM-dd HH:mm:ss'),
                updated_at: format(new Date(order.updated_at), 'yyyy-MM-dd HH:mm:ss'),
            }));

            const uniqueOrders = Array.from(new Map(formattedOrders.map(item => [item.id, item])).values());

            setAllOrders(uniqueOrders);
        } catch (err) {
            console.error(`Error fetching orders: ${err}`);
        } finally {
            setOrdersLoading(false);
        }
    }, []);

    useEffect(() => {
        if (allOrders.length === 0) {
            fetchAllOrders();
        }
    }, [fetchAllOrders, allOrders.length]);

    return (
        <OrderContext.Provider value={{ allOrders, fetchAllOrders, setAllOrders, ordersLoading, persistentData, setPersistentData }}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => {
    return useContext(OrderContext);
};
