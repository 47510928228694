import styled from 'styled-components';

export const TableContainer = styled.div`
  margin: 20px;
  font-family: Arial, sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
`;

export const SummaryTablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SummaryTableWrapper = styled.div`
  flex: 1;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

export const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid var(--border-color);
  background-color: var(--form-bg-color);
  font-weight: bold;
`;

export const PnLCellSpan = styled.span`
  color: ${props => props.value >= 0 ? 'green' : 'red'};
`;

export const InvertedPnLCellSpan = styled.span`
  color: ${props => props.value >= 0 ? 'red' : 'green'};
`;

export const OptionSellCellSpan = styled.span`
  color: green;
`;

export const OptionBuyCellSpan = styled.span`
  color: red;
`;

export const TotalPnLCellSpan = styled.span`
  background-color: ${props => props.value >= 0 ? 'lightgreen' : 'lightcoral'};
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
`;
