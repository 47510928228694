import React, { useState } from 'react';
import styles from './LoginPage.module.css';

export const LoginForm = ({ onSubmit, onResetPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (resetPasswordMode) {
      const result = await onResetPassword(email);
      if (result.success) {
        setResetPasswordMessage('Check your email for the password reset link.');
      } else {
        setError(result.error);
      }
    } else {
      const result = await onSubmit(email, password);
      if (!result.success) {
        setError(result.error);
      }
    }
  };

  return (
    <form onSubmit={resetPasswordMode ? handleSubmit : handleSubmit} className={styles.form}>
      {error && <p className={styles.error}>{error}</p>}
      {resetPasswordMessage && <p className={styles.success}>{resetPasswordMessage}</p>}
      <div className={styles.inputGroup}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      {!resetPasswordMode && (
        <div className={styles.inputGroup}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className={styles.forgotPasswordContainer}>
            <button
              type="button"
              onClick={() => setResetPasswordMode(true)}
              className={styles.forgotPasswordLink}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      )}
      <button type="submit" className={styles.primaryButton}>
        {resetPasswordMode ? 'Reset Password' : 'Login'}
      </button>
      {resetPasswordMode && (
        <div className={styles.returnToLoginContainer}>
          <button
            type="button"
            onClick={() => {
              setResetPasswordMode(false);
              setResetPasswordMessage('');
            }}
            className={styles.forgotPasswordLink}
          >
            Return to Login
          </button>
        </div>
      )}
    </form>
  );
};
