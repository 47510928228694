import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { fetchStranglesData } from '../api/optionsAPI';

const StranglesContext = createContext();

export const StranglesProvider = ({ children }) => {
    const [stranglesData, setStranglesData] = useState(() => {
        const savedData = localStorage.getItem('stranglesData');
        return savedData ? JSON.parse(savedData) : [];
    });
    const [stranglesLoading, setStranglesLoading] = useState(false);
    const [persistentData, setPersistentData] = useState(() => {
        const savedPersistentData = localStorage.getItem('stranglesPersistentData');
        return savedPersistentData ? JSON.parse(savedPersistentData) : {
            symbol: '',
            expectedPop: '85',
            expectedRoi: '10',
            expiryReq: '',
            marginOfSafety: '5',
        };
    });

    useEffect(() => {
        localStorage.setItem('stranglesData', JSON.stringify(stranglesData));
    }, [stranglesData]);

    useEffect(() => {
        localStorage.setItem('stranglesPersistentData', JSON.stringify(persistentData));
    }, [persistentData]);

    const fetchStrangles = useCallback(async () => {
        setStranglesLoading(true);
        try {
            const data = await fetchStranglesData(
                persistentData.symbol,
                persistentData.expectedPop,
                persistentData.expectedRoi,
                persistentData.expiryReq,
                persistentData.marginOfSafety
            );
            setStranglesData(data);
        } catch (err) {
            console.error(`Error fetching strangles data: ${err}`);
        } finally {
            setStranglesLoading(false);
        }
    }, [persistentData]);

    return (
        <StranglesContext.Provider value={{ 
            stranglesData, 
            setStranglesData, 
            stranglesLoading, 
            fetchStrangles, 
            persistentData, 
            setPersistentData 
        }}>
            {children}
        </StranglesContext.Provider>
    );
};

export const useStranglesContext = () => {
    return useContext(StranglesContext);
};