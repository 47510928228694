export const handleApiError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return `Error ${error.response.status}: ${error.response.data.message || 'An error occurred'}\n\nDetails: ${JSON.stringify(error.response.data, null, 2)}`;
    } else if (error.request) {
        // The request was made but no response was received
        return 'No response received from the server. Possibly a server side error.';
    } else {
        // Something happened in setting up the request that triggered an Error
        return `An error occurred while processing your request: ${error.message}`;
    }
};