import axios from 'axios';
import { decode } from 'html-entities';
import { handleApiError } from './helpers'; // Assuming you have a helpers.js file

const fnoUrlDhan = 'https://open-web-scanx.dhan.co/scanx/allfut';


const getFnOLotSizesDhan = async () => {
    try {
        const lotSize = {};

        const jsonData = {
            "Data": {
                "Seg": 2,
                "Instrument": "FUT",
                "Count": 200,
                "Page_no": 1,
                "ExpCode": -1,
            },
        };

        const response = await axios.post(fnoUrlDhan, jsonData);
        const data = response.data;

        for (const entry of data.data.list) {
            const symbol = decode(entry.sym);
            for (const foData of entry.fo_dt) {
                try {
                    lotSize[symbol] = foData.ls;
                    break;
                } catch (e) {
                    console.error(`Exception while setting lot size: ${e}`);
                    console.error(`${symbol} does not have a lot size. Setting to 1`);
                    lotSize[symbol] = 1;
                }
            }
        }

        return lotSize;

    } catch (error) {
        throw handleApiError(error);
    }
};

// Replace your old fetchFnOSymbols with this:
const fetchFnOSymbols = async () => {
    return await getFnOLotSizesDhan();
};

export default fetchFnOSymbols;
