import React, { useMemo, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  .react-select__control {
    min-height: 30px;
    height: 30px;
    border-radius: 4px;
    border-color: var(--border-color);
    background-color: var(--bg-color);
    color: var(--text-color);
    &:hover {
      border-color: var(--border-hover-color);
    }
  }
  .react-select__value-container {
    padding: 0 8px;
    max-height: 28px;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .react-select__multi-value {
    background-color: var(--multi-value-bg-color);
    border-radius: 2px;
    margin: 2px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
  .react-select__multi-value__label {
    font-size: 12px;
    color: var(--multi-value-text-color);
    padding: 2px 4px;
  }
  .react-select__multi-value__remove {
    color: var(--multi-value-text-color);
    &:hover {
      background-color: var(--multi-value-remove-hover-bg-color);
      color: var(--multi-value-remove-hover-text-color);
    }
  }
  .react-select__menu {
    z-index: 100;
    background-color: var(--menu-bg-color);
    color: var(--text-color);
  }
  .react-select__option {
    padding: 8px 12px;
    background-color: var(--option-bg-color); /* Ensure background is not transparent */
    &:active {
      background-color: var(--option-active-bg-color);
    }
  }
  .react-select__option--is-focused {
    background-color: var(--option-focused-bg-color);
  }
  .react-select__option--is-selected {
    background-color: var(--option-selected-bg-color);
    color: var(--option-selected-text-color);
  }
  .react-select__menu-list {
    max-height: 200px;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color);
      border-radius: 4px;
    }
  }
  .react-select__select-all {
    padding: 8px 12px;
    color: var(--select-all-text-color);
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    &:hover {
      background-color: var(--select-all-hover-bg-color);
    }
  }
  .react-select__multi-value--is-disabled {
    background-color: transparent;
    padding: 0;
  }
`;

const SelectAllOption = ({ options, value, onChange, selectAllLabel, clearAllLabel }) => {
  const allSelected = value.length === options.length;
  const label = allSelected ? clearAllLabel : selectAllLabel;

  const handleClick = useCallback(() => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options);
    }
  }, [allSelected, onChange, options]);

  return (
    <div className="react-select__select-all" onClick={handleClick}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const values = getValue();
  if (index === 0) {
    return (
      <components.MultiValue {...props}>
        {values.length} selected
      </components.MultiValue>
    );
  }
  return null;
};

const Option = ({ children, ...props }) => (
  <components.Option {...props}>
    <div title={children}>{children}</div>
  </components.Option>
);

const Menu = (props) => (
  <components.Menu {...props}>
    <SelectAllOption
      options={props.selectProps.options}
      value={props.selectProps.value}
      onChange={(options) => props.selectProps.onChange(options, { action: 'select-option' })}
      selectAllLabel="Select All"
      clearAllLabel="Clear All"
    />
    {props.children}
  </components.Menu>
);

const MultiSelectFilter = ({ column: { filterValue, setFilter, preFilteredRows, id }, updateFilterState }) => {
  const options = useMemo(() => {
    const optionSet = new Set();
    preFilteredRows.forEach(row => {
      optionSet.add(row.values[id]);
    });
    return [...optionSet].map(option => ({ value: option, label: option }));
  }, [id, preFilteredRows]);

  const handleChange = useCallback((selectedOptions) => {
    const newFilterValue = selectedOptions ? selectedOptions.map(option => option.value) : undefined;
    setFilter(newFilterValue);
    updateFilterState(id, newFilterValue);
  }, [setFilter, updateFilterState, id]);

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
  }, [filterValue, setFilter]);

  return (
    <div onClick={(e) => e.stopPropagation()} style={{ width: '100%' }}>
      <StyledSelect
        isMulti
        options={options}
        onChange={handleChange}
        value={filterValue ? filterValue.map(value => ({ value, label: value })) : []}
        placeholder={`Filter ${id}`}
        classNamePrefix="react-select"
        isClearable
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ IndicatorSeparator: () => null, MultiValue, Option, Menu }}
        isSearchable
        controlShouldRenderValue
        menuPlacement="auto"
        maxMenuHeight={200}
        noOptionsMessage={() => "No options available"}
      />
    </div>
  );
};

export default MultiSelectFilter;