import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { fetchStrategies, fetchOpenTradeInstances } from '../api/tradeInstancesAPI';
import { format } from 'date-fns';

const TradeContext = createContext();

export const TradeProvider = ({ children }) => {
    const [allStrategies, setAllStrategies] = useState(() => {
        const savedStrategies = localStorage.getItem('allStrategies');
        return savedStrategies ? JSON.parse(savedStrategies) : [];
    });
    const [openPositions, setOpenPositions] = useState(() => {
        const savedPositions = localStorage.getItem('openPositions');
        return savedPositions ? JSON.parse(savedPositions) : [];
    });
    const [positionsLoading, setPositionsLoading] = useState(false);

    useEffect(() => {
        localStorage.setItem('allStrategies', JSON.stringify(allStrategies));
    }, [allStrategies]);

    useEffect(() => {
        localStorage.setItem('openPositions', JSON.stringify(openPositions));
    }, [openPositions]);

    const fetchAllStrategies = useCallback(async () => {
        try {
            const strategies = await fetchStrategies();
            setAllStrategies(strategies);
        } catch (err) {
            console.error(`Error fetching strategies: ${err}`);
        }
    }, []);

    const fetchAllOpenPositions = useCallback(async () => {
        setPositionsLoading(true);
        try {
            const strategies = await fetchStrategies();

            let allInstances = [];
            let pageNum = 1;
            const limit = 100;
            let hasMoreData = true;

            while (hasMoreData) {
                const instances = await fetchOpenTradeInstances(pageNum, limit);
                if (instances.length > 0) {
                    allInstances = [...allInstances, ...instances];
                    pageNum++;
                    if (instances.length < limit) {
                        hasMoreData = false;
                    }
                } else {
                    hasMoreData = false;
                }
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            const combined = allInstances.map(instance => {
                const strategy = strategies.find(s => s.id === instance.strategy_id);
                return {
                    ...instance,
                    strategy_name: strategy ? strategy.strategy_name : 'Unknown Strategy',
                    created_at: format(new Date(instance.created_at), 'yyyy-MM-dd HH:mm:ss'),
                    updated_at: format(new Date(instance.updated_at), 'yyyy-MM-dd HH:mm:ss'),
                };
            });

            const uniqueCombined = Array.from(new Map(combined.map(item => [item.id, item])).values());

            setOpenPositions(uniqueCombined);
        } catch (err) {
            console.error(`Error fetching open positions: ${err}`);
        } finally {
            setPositionsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAllStrategies();
    }, [fetchAllStrategies]);

    useEffect(() => {
        fetchAllOpenPositions();
    }, [fetchAllOpenPositions]);

    return (
        <TradeContext.Provider value={{ allStrategies, openPositions, fetchAllOpenPositions, setOpenPositions, positionsLoading }}>
            {children}
        </TradeContext.Provider>
    );
};

export const useTradeContext = () => {
    return useContext(TradeContext);
};
